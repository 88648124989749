var Common = function() {

// show hide menu responsive
var animationMenu = function () {
  $('.nav-mobile').click(function () {
    $(this).toggleClass('change');
    $('.common-menu').toggleClass('open-nav');
    $('body').toggleClass('overflow-hidden');
  });
}

// scroll button nave menu
var scrollEvent = function () {
  var fixedOffset;
  if ($('.fixed-offset').length > 0 ) {
    fixedOffset = $('.fixed-offset').offset().top;
  }

  $(window).on('scroll', function () {
    if ($( window ).width() > 991) {
      $('.common-menu').hide();
    }

    if ($( window ).width() < 768) {
      $('.common-menu').show();
    }
    
    if ($(window).scrollTop() >= fixedOffset) {
      $('.common-menu').addClass('fixed');
    } else {
      $('.common-menu').removeClass('fixed');
    }
  });
}

  var topSlider = function() {
    var slickOptions = {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      centerMode: false,
      vertical: true,
      focusOnSelect: true,
      nextArrow: null,
      prevArrow: null,
      infinite: true,
      arrows: true,
      autoplay: true,
      verticalScrolling: true,
      autoplaySpeed: 2500,
      adaptiveHeight: true
    };
    if ($('.sp-slick, .pc-slick').length > 0) {
      $('.sp-slick').slick(slickOptions);
      $('.pc-slick').slick(slickOptions);
    }
  }

  return {
    init: function () {
      animationMenu();
      scrollEvent();
      // topSlider();
    }
  }
}();

$(function () {
  Common.init();
});
